import classNames from 'classnames';
import * as React from 'react';
import { IValidatedFieldProps } from './ValidationModels';
import { FieldError } from './FieldError';
import { ValidationLabel } from './ValidationLabel';

export const ValidatedField: React.FC<IValidatedFieldProps> = (props) => {
    const { className, children, label, touched, error, required } = props;
    const isInvalid = touched && !!error;
    const isValid = touched && !isInvalid;
    const classes = classNames(
        'form-field',
        {
            'is-invalid': isInvalid,
            'is-valid': isValid,
        },
        className,
    );

    return (
        <div className={classes}>
            <ValidationLabel className="my-2" htmlFor={props.id} required={required}>{label}</ValidationLabel>
            <div id={`${props.id}-container`}>
                {children}
                <FieldError touched={touched} error={error} />
            </div>
        </div>
    );
};
