import { ThunkAction } from 'components/common/AppProvider';
import { normalize } from 'normalizr';
import { DynamicMenusApi, InsightApiSchema } from 'services/ApiService';
import { abortRequests } from 'services/ApiService/Http';
import {
    DynamicMenu,
    DynamicMenuItem,
    UpdateDynamicMenuItemRanksRequest,
} from 'services/ApiService/Insight/InsightApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergeInsightEntities } from 'store/Normalizr/NormalizrAction';
import {
    createSearchDynamicMenusAction,
    createSearchDynamicMenusFailureAction,
    createSearchDynamicMenusSuccessAction,
    createCreateDynamicMenuAction,
    createCreateDynamicMenuSuccessAction,
    createCreateDynamicMenuFailureAction,
    createFetchDynamicMenusAction,
    createFetchDynamicMenusSuccessAction,
    createFetchDynamicMenusFailureAction,
    createUpdateDynamicMenuItemsAction,
    createUpdateDynamicMenuItemsSuccessAction,
    createUpdateDynamicMenuItemsFailureAction,
    createDeleteDynamicMenuSuccessAction,
    createDeleteDynamicMenuAction,
    createDeleteDynamicMenuFailureAction,
    createDeleteDynamicMenuItemsSuccessAction,
    createDeleteDynamicMenuItemsAction,
    createDeleteDynamicMenuItemsFailureAction,
    createCreateDynamicMenuItemsAction,
    createCreateDynamicMenuItemsFailureAction,
    createCreateDynamicMenuItemsSuccessAction,
    createUpdateDynamicMenuItemRanksAction,
    createUpdateDynamicMenuItemRanksSuccessAction,
    createUpdateDynamicMenuItemRanksFailureAction,
    createUpdateDynamicMenuAction,
    createUpdateDynamicMenuFailureAction,
    createUpdateDynamicMenuSuccessAction,
} from './DynamicMenusActions';

export const searchDynamicMenus = (): ThunkAction<Promise<DynamicMenu[]>> => async (dispatch, getState) => {
    const state = getState();
    if (!state.dynamicMenus.search.didInvalidate) {
        const promise = PromiseStore.get<DynamicMenu[]>('searchDynamicMenus');
        if (promise) {
            return await promise;
        }
    }

    abortRequests('/api/v1/dynamic-menus/search', 'POST');

    const { searchTerms, skip, take } = state.dynamicMenus.search;

    try {
        const fetchTask = (async () => {
            const data = await DynamicMenusApi.search({
                terms: searchTerms,
                skip,
                take,
            });
            const normalizedData = normalize(data.menus, InsightApiSchema.DynamicMenuSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createSearchDynamicMenusSuccessAction(normalizedData.result, data.totalResults));
            return data.menus || [];
        })();

        PromiseStore.set(fetchTask, 'searchDynamicMenus');

        dispatch(createSearchDynamicMenusAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createSearchDynamicMenusFailureAction());
        logError(error);
        throw error;
    }
};

export const getDynamicMenus = (): ThunkAction<Promise<DynamicMenu[]>> => async (dispatch, getState) => {
    const state = getState();

    const dynamicMenusRequest = state.dynamicMenus.dynamicMenus;
    if (dynamicMenusRequest && (dynamicMenusRequest.isFetching || !dynamicMenusRequest.didInvalidate)) {
        const promise = PromiseStore.get<DynamicMenu[]>('getDynamicMenus');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await DynamicMenusApi.getAll();
            const normalizedData = normalize(data.dynamicMenus, InsightApiSchema.DynamicMenuSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchDynamicMenusSuccessAction(normalizedData.result));
            return data.dynamicMenus || [];
        })();

        PromiseStore.set(fetchTask, 'getDynamicMenus');

        dispatch(createFetchDynamicMenusAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchDynamicMenusFailureAction());
        logError(error);
        throw error;
    }
};

export const createDynamicMenu = (name: string): ThunkAction<Promise<number>> => async (dispatch, getState) => {
    const state = getState();

    const normalizedName = name.toLowerCase();
    const isUpdating = state.dynamicMenus.create[normalizedName];
    if (isUpdating) {
        const promise = PromiseStore.get<number>('createDynamicMenu', normalizedName);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const dynamicMenuId = await DynamicMenusApi.createDynamicMenu({ name: normalizedName });
            dispatch(createCreateDynamicMenuSuccessAction(normalizedName, dynamicMenuId));
            return dynamicMenuId;
        })();

        PromiseStore.set(fetchTask, 'createDynamicMenu', normalizedName);

        dispatch(createCreateDynamicMenuAction(normalizedName));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createCreateDynamicMenuFailureAction(normalizedName));
        logError(error);
        throw error;
    }
};

export const updateDynamicMenu = (id: number, name: string): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const isUpdating = state.dynamicMenus.update[id];
    if (isUpdating) {
        const promise = PromiseStore.get('updateDynamicMenu', id);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.updateDynamicMenu(id, { name: name });
            dispatch(createUpdateDynamicMenuSuccessAction(id));
        })();

        PromiseStore.set(fetchTask, 'updateDynamicMenu', id);

        dispatch(createUpdateDynamicMenuAction(id));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createUpdateDynamicMenuFailureAction(id));
        logError(error);
        throw error;
    }
};

export const deleteDynamicMenu = (id: number): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();

    const isUpdating = state.dynamicMenus.update[id];
    if (isUpdating) {
        const promise = PromiseStore.get('deleteDynamicMenu', id);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.deleteDynamicMenu(id);
            dispatch(createDeleteDynamicMenuSuccessAction(id));
        })();

        PromiseStore.set(fetchTask, 'deleteDynamicMenu', id);

        dispatch(createDeleteDynamicMenuAction(id));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createDeleteDynamicMenuFailureAction(id));
        logError(error);
        throw error;
    }
};

export const createDynamicMenuItem = (dynamicMenuId: number, dynamicMenuItem: DynamicMenuItem): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const isUpdating = state.dynamicMenus.createItems[dynamicMenuId];

    if (isUpdating) {
        const promise = PromiseStore.get('createDynamicMenuItem', dynamicMenuId);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.createDynamicMenuItem(dynamicMenuId, dynamicMenuItem);
            dispatch(createCreateDynamicMenuItemsSuccessAction(dynamicMenuId));
        })();

        PromiseStore.set(fetchTask, 'createDynamicMenuItem', dynamicMenuId);

        dispatch(createCreateDynamicMenuItemsAction(dynamicMenuId));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createCreateDynamicMenuItemsFailureAction(dynamicMenuId));
        logError(error);
        throw error;
    }
};

export const updateDynamicMenuItem = (id: number, dynamicMenuItem: DynamicMenuItem): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const isUpdating = state.dynamicMenus.updateItems[id];

    if (isUpdating) {
        const promise = PromiseStore.get('updateDynamicMenuItem', id);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.updateDynamicMenuItem(id, dynamicMenuItem);
            dispatch(createUpdateDynamicMenuItemsSuccessAction(id));
        })();

        PromiseStore.set(fetchTask, 'updateDynamicMenuItem', id);

        dispatch(createUpdateDynamicMenuItemsAction(id));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createUpdateDynamicMenuItemsFailureAction(id));
        logError(error);
        throw error;
    }
};

export const deleteDynamicMenuItem = (id: number): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const isUpdating = state.dynamicMenus.deleteItems[id];

    if (isUpdating) {
        const promise = PromiseStore.get('deleteDynamicMenuItem', id);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.deleteDynamicMenuItem(id);
            dispatch(createDeleteDynamicMenuItemsSuccessAction(id));
        })();

        PromiseStore.set(fetchTask, 'deleteDynamicMenuItem', id);

        dispatch(createDeleteDynamicMenuItemsAction(id));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createDeleteDynamicMenuItemsFailureAction(id));
        logError(error);
        throw error;
    }
};

export const updateDynamicMenuItemRanks = (request: UpdateDynamicMenuItemRanksRequest): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const isUpdating = state.dynamicMenus.updateItemRanks.isFetching;

    if (isUpdating) {
        const promise = PromiseStore.get('updateDynamicMenuItemRanks');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await DynamicMenusApi.updateDynamicMenuItemRanks(request);
            dispatch(createUpdateDynamicMenuItemRanksSuccessAction());
        })();

        PromiseStore.set(fetchTask, 'updateDynamicMenuItemRanks');

        dispatch(createUpdateDynamicMenuItemRanksAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createUpdateDynamicMenuItemRanksFailureAction());
        logError(error);
        throw error;
    }
};