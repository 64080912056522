import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import React, { FC, MouseEvent, ElementType } from 'react';

export interface InputPicker {
    id?: string;
    className?: string;
    inputRef: ((instance: any) => void) | React.RefObject<any> | null | undefined;
    inputProps?: React.HTMLAttributes<HTMLInputElement>;
    inputStyle?: React.CSSProperties;
    onInputClick: (event: MouseEvent<HTMLInputElement>) => void;
    onClearClick: (event: MouseEvent<HTMLButtonElement>) => void;
    onIconClick: (event: MouseEvent<HTMLInputElement>) => void;
    iconType?: ElementType;
}

export const InputPicker: FC<InputPicker> = ({ id, className, iconType, onInputClick, onClearClick, onIconClick, inputRef, inputProps, inputStyle }) => {
    return <div className="input-group">
        <div className="align-items-stretch py-0 pe-0 pr-0 form-control d-flex h-auto align-items-start">
            <div className="flex-grow-1 d-flex justify-content-start align-items-center badges-container">
                {iconType ? <div className="input-group-prepend input-group-merged">
                    {React.createElement(
                        iconType,
                        {
                            sx: { fontSize: '18px' },
                            role: 'button',
                            className: 'text-muted icon',
                            onClick: onIconClick,
                        })}
                </div> : null}
                <input
                    id={id}
                    ref={inputRef}
                    {...inputProps}
                    onClick={onInputClick}
                    autoComplete="off"
                    className={classNames(className, inputProps?.className)}
                    style={inputStyle} />
            </div>
            <div className="d-flex align-items-center align-self-start h-100">
                <button aria-label="Clear" className="btn btn-flat-primary" type="button" onClick={onClearClick}>
                    <CloseIcon fontSize="inherit" />
                </button>
            </div>
        </div>
    </div>;
};
