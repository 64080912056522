import { ApiRequest, Reducer } from 'store';
export const initialState = {
    universes: {
        universes: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    products: {
        products: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categories: {
        categories: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categoryAssetClasses: {
        categoryAssetClasses: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    keywords: {
        keywords: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    sectors: {
        sectors: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    languages: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]>,
} as const;

export type ReferentialState = typeof initialState;

export const ReferentialReducer: Reducer<ReferentialState> = (state = initialState, action) => {
    switch (action.type) {
        case '@UNIVERSES/FETCH_UNIVERSES':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: true,
                },
            };
        case '@UNIVERSES/FETCH_UNIVERSES_SUCCESS':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.universeIds,
                },
            };
        case '@UNIVERSES/FETCH_UNIVERSES_FAILURE':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PRODUCTS/FETCH_PRODUCTS':
            return {
                ...state,
                products: {
                    ...state.products,
                    isFetching: true,
                },
            };
        case '@PRODUCTS/FETCH_PRODUCTS_SUCCESS':
            return {
                ...state,
                products: {
                    ...state.products,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.productIds,
                },
            };
        case '@PRODUCTS/FETCH_PRODUCTS_FAILURE':
            return {
                ...state,
                products: {
                    ...state.products,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@CATEGORIES/FETCH_CATEGORIES':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: true,
                },
            };
        case '@CATEGORIES/FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.categoriesId,
                },
            };
        case '@CATEGORIES/FETCH_CATEGORIES_FAILURE':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: true,
                },
            };
        case '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES_SUCCESS':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.categoryAssetClassIds,
                },
            };
        case '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES_FAILURE':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@KEYWORDS/FETCH_KEYWORDS':
            return {
                ...state,
                keywords: {
                    ...state.keywords,
                    isFetching: true,
                },
            };
        case '@KEYWORDS/FETCH_KEYWORDS_SUCCESS':
            return {
                ...state,
                keywords: {
                    ...state.keywords,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.keywordIds,
                },
            };
        case '@KEYWORDS/FETCH_KEYWORDS_FAILURE':
            return {
                ...state,
                keywords: {
                    ...state.keywords,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@SECTORS/FETCH_SECTORS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: true,
                },
            };
        case '@SECTORS/FETCH_SECTORS_SUCCESS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.sectorIds,
                },
            };
        case '@SECTORS/FETCH_SECTORS_FAILURE':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
            
        case '@LANGUAGES/FETCH_LANGUAGES':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: true,
                },
            };
        case '@LANGUAGES/FETCH_LANGUAGES_SUCCESS':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.languageIsoCodes,
                },
            };
        case '@LANGUAGES/FETCH_LANGUAGES_FAILURE':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
        default:
            return state;
    }
};

