import { ApiRequest, Reducer } from 'store';

export interface IPersonRequestMap {
    readonly [icId: string]: ApiRequest;
}

export const initialState = {
    navigateAs: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]>,
    persons: {} as IPersonRequestMap,
    checkAndSynchronize: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest,
} as const;

export type PersonsState = typeof initialState;

export const PersonsReducer: Reducer<PersonsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: true,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.icIds,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PERSONS/FETCH_PERSONS':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PERSONS/FETCH_PERSONS_SUCCESS':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PERSONS/FETCH_PERSONS_FAILURE':
            return {
                ...state,
                persons: createFetchPersonsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        default:
            return state;
    }
};

const createFetchPersonsState = (state: PersonsState, icIds: string[], apiRequest: ApiRequest) => {
    let statePersons = {
        ...state.persons,
    };
    for (const icId of icIds) {
        statePersons = {
            ...statePersons,
            [icId]: {
                ...state.persons[icId],
                ...apiRequest,
            },
        };
    }
    return statePersons;
};
