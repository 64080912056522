import classNames from 'classnames';
import { useAppContext } from 'components/common/AppProvider';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { hasRight, Permissions } from 'services/RightsService';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';

export const Menu: React.FC<{ className?: string }> = ({ className }) => {
    const { state: { appContext: { loggedUserId }, entities } } = useAppContext();
    const loggedUser = InsightSelectors.getLoggedUser(loggedUserId, entities.insight);
    const isAdministrator = hasRight(loggedUser, Permissions.Administrator);

    const location = useLocation();

    return <ul className={className} >
        <li className="navbar-item">
            <NavLink
                to={RoutePaths.Home.url()}
                end
                className={({ isActive }) => classNames('navbar-link',
                    {
                        'active': isActive || location.pathname.toLowerCase().startsWith('/pages'),
                    })}
            >
                Pages
            </NavLink>
        </li>
        {isAdministrator && <li className="navbar-item">
            <NavLink
                to={RoutePaths.DynamicMenus.url()}
                className={({ isActive }) => classNames('navbar-link',
                    {
                        'active': isActive,
                    })}
            >
                Dynamic Menus
            </NavLink>
        </li>
        }
    </ul>;
};
