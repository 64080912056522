import {
    Language,
    LoggedUser,
    Page,
    Person,
} from './InsightApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './InsightApiSchema';

export class BaseInsightApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.LoggedUser:
                return (value as LoggedUser).id;

            case ObjectsWithoutIdPropertyEnum.Page:
                const page = (value as Page);
                return page.shortName?.toLowerCase() || '';

            case ObjectsWithoutIdPropertyEnum.Person:
                return (value as Person).icId;

            case ObjectsWithoutIdPropertyEnum.Language:
                return (value as Language).isoCode || '';
        }
        return '';
    }
}
