import { configuration } from 'config/constants';
import { generatePath } from 'react-router-dom';

export enum EditType {
    Page = 'Page',
    Widget = 'Widget',
}

export const RoutePaths = {
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotAuthorizedContactUs: {
            route: '/error/not-authorized/contact-us',
            url: () => RoutePaths.Errors.NotAuthorizedContactUs.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    Home: {
        route: '/',
        url: (search?: string) => RoutePaths.Home.route + getQueryParameters({
            search,
        }),
    },
    DynamicMenus: {
        route: '/dynamic-menus',
        url: (search?: string) => RoutePaths.DynamicMenus.route + getQueryParameters({
            search,
        }),
        Create: {
            route: '/dynamic-menus/create',
            url: () => RoutePaths.DynamicMenus.Create.route,
        },
        Edit: {
            route: '/dynamic-menus/:id/edit',
            url: (id: number) => generatePath(RoutePaths.DynamicMenus.Edit.route, { id: `${id}` }),
        },
        Item: {
            Edit: {
                route: '/dynamic-menus/:id/edit/items/:itemId/edit',
                url: (id: number, itemId: number) => generatePath(RoutePaths.DynamicMenus.Item.Edit.route, { id: `${id}`, itemId: `${itemId}` }),
            },
            Add: {
                route: '/dynamic-menus/:id/items/add',
                url: (id: number) => generatePath(RoutePaths.DynamicMenus.Item.Add.route, { id: `${id}` }),
            },
        },
    },
    Insight: {
        Page: {
            route: '/page/:shortName',
            url: (shortName: string) => configuration.externalLinks.insight + generatePath(RoutePaths.Insight.Page.route, { shortName: `${cleanUrlParameter(shortName)}` }),
        },
    },
    Pages: {
        Create: {
            route: '/pages/create',
            url: () => RoutePaths.Pages.Create.route,
        },
        Edit: {
            route: '/pages/:shortName/edit',
            url: (shortName: string) => generatePath(RoutePaths.Pages.Edit.route, { shortName: `${cleanUrlParameter(shortName)}` }),
            Widget: {
                route: '/pages/:shortName/edit',
                url: (shortName: string, widgetId: number) => generatePath(RoutePaths.Pages.Edit.Widget.route, { shortName: `${cleanUrlParameter(shortName)}` })
                    + getQueryParameters({ widgetId }),
                Add: {
                    route: '/pages/:shortName/edit',
                    url: (shortName: string, sectionId: number, columnId: number) => generatePath(RoutePaths.Pages.Edit.Widget.route, { shortName: `${cleanUrlParameter(shortName)}` })
                        + getQueryParameters({ sectionId, columnId, type: EditType.Widget }),
                },
            },
            Page: {
                route: '/pages/:shortName/edit',
                url: (shortName: string) => generatePath(RoutePaths.Pages.Edit.Page.route, { shortName: `${cleanUrlParameter(shortName)}` })
                    + getQueryParameters({ type: EditType.Page }),
            },
            Section: {
                route: '/pages/:shortName/edit',
                url: (shortName: string, sectionId: number) => generatePath(RoutePaths.Pages.Edit.Section.route, { shortName: `${cleanUrlParameter(shortName)}` })
                    + getQueryParameters({ sectionId }),
            },
            Column: {
                route: '/pages/:shortName/edit',
                url: (shortName: string, sectionId: number, columnId: number) => generatePath(RoutePaths.Pages.Edit.Section.route, { shortName: `${cleanUrlParameter(shortName)}` })
                    + getQueryParameters({ sectionId, columnId }),
            },
        },
    },
} as const;

export type RouteParams = {
    Errors: {
        NotAuthorized: ExtractRouteParams<typeof RoutePaths.Errors.NotAuthorized.route, string>,
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    Home: ExtractRouteParams<typeof RoutePaths.Home.route, string>,
    Pages: {
        Create: ExtractRouteParams<typeof RoutePaths.Pages.Create.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.Pages.Edit.route, string>,
    },
    DynamicMenus: {
        Create: ExtractRouteParams<typeof RoutePaths.DynamicMenus.Create.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.DynamicMenus.Edit.route, string>,
        Item: {
            Edit: ExtractRouteParams<typeof RoutePaths.DynamicMenus.Item.Edit.route, string>,
            Add: ExtractRouteParams<typeof RoutePaths.DynamicMenus.Item.Add.route, string>,
        }
    }
};

const cleanUrlParameter = (parameter?: string) => {
    if (!parameter) {
        return parameter;
    }

    return encodeURIComponent(parameter);
};

const getQueryParameters = (parameters: Record<string, string | number | boolean | undefined>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1] || entry[1] === 0)
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
