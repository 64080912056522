import { ApiRequest, Reducer } from 'store';

export interface IDynamicMenuCreateRequestMap {
    readonly [name: string]: boolean;
}

export interface IDynamicMenuUpdateRequestMap {
    readonly [id: number]: boolean;
}

export interface IDynamicMenuDeleteRequestMap {
    readonly [id: number]: boolean;
}

export type SearchDynamicMenuRequest = ApiRequest<number[]> & {
    searchTerms?: string;
    skip: number;
    take: number;
    totalResults: number;
};

export interface IDynamicMenuItemCreateRequestMap {
    readonly [id: number]: boolean;
}

export interface IDynamicMenuItemUdpateRequestMap {
    readonly [id: number]: boolean;
}

export interface IDynamicMenuItemDeleteRequestMap {
    readonly [id: number]: boolean;
}

export const initialState = {
    dynamicMenus: {} as ApiRequest<number[]>,
    create: {} as IDynamicMenuCreateRequestMap,
    update: {} as IDynamicMenuUpdateRequestMap,
    search: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
        searchTerms: undefined,
        skip: 0,
        take: 20,
        totalResults: 0,
    } as SearchDynamicMenuRequest,
    createItems: {} as IDynamicMenuItemCreateRequestMap,
    updateItems: {} as IDynamicMenuItemUdpateRequestMap,
    deleteItems: {} as IDynamicMenuItemDeleteRequestMap,
    updateItemRanks: {
        isFetching: false as boolean,
    },
} as const;

export type DynamicMenusState = typeof initialState;

export const DynamicMenusReducer: Reducer<DynamicMenusState> = (state = initialState, action) => {
    switch (action.type) {
        case '@DYNAMIC_MENUS/SET_SEARCH_TERMS':
            return {
                ...state,
                search: createSearchDynamicMenusState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                        searchTerms: action.payload.searchTerms,
                    }),
            };

        case '@DYNAMIC_MENUS/SET_PAGINATION':
            return {
                ...state,
                search: createSearchDynamicMenusState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    }),
            };

        case '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS':
            return {
                ...state,
                search: createSearchDynamicMenusState(
                    state,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS_SUCCESS':
            return {
                ...state,
                search: createSearchDynamicMenusState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.dynamicMenuIds,
                        totalResults: action.payload.totalResults,
                    }),
            };

        case '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS_FAILURE':
            return {
                ...state,
                search: invalidateSearch(state),
            };

        case '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS':
            return {
                ...state,
                dynamicMenus: {
                    ...state.dynamicMenus,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS_SUCCESS':
            return {
                ...state,
                dynamicMenus: {
                    ...state.dynamicMenus,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.dynamicMenus,
                },
            };

        case '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS_FAILURE':
            return {
                ...state,
                dynamicMenus: {
                    ...state.dynamicMenus,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU':
            return {
                ...state,
                create: createCreateDynamicMenuState(
                    state,
                    action.payload.name,
                    true),
            };

        case '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU_SUCCESS':
            return {
                ...state,
                create: createCreateDynamicMenuState(
                    state,
                    action.payload.name,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
                search: invalidateSearch(state),
            };

        case '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU_FAILURE':
            return {
                ...state,
                create: createCreateDynamicMenuState(
                    state,
                    action.payload.name,
                    false),
            };

        case '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU':
            return {
                ...state,
                update: createUpdateDynamicMenuState(
                    state,
                    action.payload.id,
                    true),
            };

        case '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU_SUCCESS':
            return {
                ...state,
                update: createUpdateDynamicMenuState(
                    state,
                    action.payload.id,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
                search: invalidateSearch(state),
            };

        case '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU_FAILURE':
            return {
                ...state,
                update: createUpdateDynamicMenuState(
                    state,
                    action.payload.id,
                    false),
            };

        case '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU':
            return {
                ...state,
                update: createDeleteDynamicMenuState(
                    state,
                    action.payload.id,
                    true),
            };

        case '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU_SUCCESS':
            return {
                ...state,
                update: createDeleteDynamicMenuState(
                    state,
                    action.payload.id,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
                search: invalidateSearch(state),
            };

        case '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU_FAILURE':
            return {
                ...state,
                update: createDeleteDynamicMenuState(
                    state,
                    action.payload.id,
                    false),
            };

        case '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM':
            return {
                ...state,
                createItems: createCreateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    true),
            };

        case '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM_SUCCESS':
            return {
                ...state,
                createItems: createCreateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
            };

        case '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM_FAILURE':
            return {
                ...state,
                createItems: createCreateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM':
            return {
                ...state,
                updateItems: createUpdateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    true),
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_SUCCESS':
            return {
                ...state,
                updateItems: createUpdateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_FAILURE':
            return {
                ...state,
                updateItems: createUpdateDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
            };

        case '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM':
            return {
                ...state,
                deleteItems: createDeleteDynamicMenuItemState(
                    state,
                    action.payload.id,
                    true),
            };

        case '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM_SUCCESS':
            return {
                ...state,
                deleteItems: createDeleteDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
                dynamicMenus: invalidateDynamicMenus(state),
            };

        case '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM_FAILURE':
            return {
                ...state,
                deleteItems: createDeleteDynamicMenuItemState(
                    state,
                    action.payload.id,
                    false),
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS':
            return {
                ...state,
                updateItemRanks: {
                    isFetching: true,
                },
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS_SUCCESS':
            return {
                ...state,
                updateItemRanks: {
                    isFetching: false,
                },
                dynamicMenus: invalidateDynamicMenus(state),
            };

        case '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS_FAILURE':
            return {
                ...state,
                updateItemRanks: {
                    isFetching: false,
                },
            };

        default:
            return state;
    }
};

const createSearchDynamicMenusState = (state: DynamicMenusState, apiRequest: Partial<SearchDynamicMenuRequest>) => {
    return {
        ...state.search,
        ...apiRequest,
    };
};

const createCreateDynamicMenuState = (state: DynamicMenusState, name: string, isFetching: boolean) => {
    const normalizedName = name.toLowerCase();
    return {
        ...state.create,
        [normalizedName]: isFetching,
    };
};

const createUpdateDynamicMenuState = (state: DynamicMenusState, id: number, isFetching: boolean) => {
    return {
        ...state.create,
        [id]: isFetching,
    };
};

const createDeleteDynamicMenuState = (state: DynamicMenusState, id: number, isFetching: boolean) => {
    return {
        ...state.create,
        [id]: isFetching,
    };
};

const createCreateDynamicMenuItemState = (state: DynamicMenusState, id: number, isFetching: boolean) => {
    return {
        ...state.createItems,
        [id]: isFetching,
    };
};

const createUpdateDynamicMenuItemState = (state: DynamicMenusState, id: number, isFetching: boolean) => {
    return {
        ...state.updateItems,
        [id]: isFetching,
    };
};

const createDeleteDynamicMenuItemState = (state: DynamicMenusState, id: number, isFetching: boolean) => {
    return {
        ...state.deleteItems,
        [id]: isFetching,
    };
};

const invalidateDynamicMenus = (state: DynamicMenusState) => {
    return {
        ...state.dynamicMenus,
        didInvalidate: true,
        isFetching: false,
    };
};

const invalidateSearch = (state: DynamicMenusState) => {
    return createSearchDynamicMenusState(
        state,
        {
            isFetching: false,
            didInvalidate: true,
        });
};