import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ValidatedField } from '../Validation';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { PublicationType } from 'services/ApiService/Insight/InsightApiClient';
import { getEnumValues } from 'services/EnumService';

export interface IAddPublicationTypesProps {
    id: string;
    className?: string;
    inputClassName?: string;
    onChange: (publicationType: PublicationType[]) => void;
    selected: PublicationType[];
    touched: boolean | undefined;
    errors: string | undefined;
    required?: boolean;
    label: string;
    placeholder: string;
}
export const AddPublicationTypes: React.FC<IAddPublicationTypesProps> = ({ required = false, label, id, className, inputClassName, selected, errors, touched, placeholder, onChange }) => {

    const publicationTypes = getEnumValues<PublicationType>(PublicationType);
    
    const handleChange = (values: Option[]) => {
        onChange(values as PublicationType[]);
    };
    
    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={errors}
        required={required}
        className={className}
    >
        <Typeahead
            id="sectorIds"
            clearButton
            labelKey={publicationType => (publicationType as PublicationType)}
            caseSensitive={false}
            options={publicationTypes}
            placeholder={placeholder}
            onChange={handleChange}
            selected={publicationTypes.filter(item => selected.find(id => (PublicationType[id] as PublicationType) === item))}
            selectHint={(shouldSelect, e) => {
                const key = e.key || e.keyCode;
                return key === 'Enter' || key === 13 || shouldSelect;
            }}
            inputProps={{
                id: 'publicationTypes',
                className: inputClassName,
                onInput: (e) => !e.currentTarget.value && (e.nativeEvent as InputEvent).inputType !== 'deleteContentBackward' && handleChange([]),
            }}
            multiple={true}
            flip={true}
            allowNew={false}
        />
    </ValidatedField>;
};
