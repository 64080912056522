import classNames from 'classnames';
import * as React from 'react';

export interface IFieldErrorProps {
    touched?: boolean;
    error?: any;
    className?: string;
}

export const FieldError: React.FC<IFieldErrorProps> = (props) => {
    const { className, touched, error } = props;
    const isInvalid = touched && typeof error === 'string' && error;
    const fakeFormControlClasses = classNames(
        'form-control',
        { 'is-invalid': isInvalid },
    );
    const classes = classNames(
        'invalid-feedback',
        className,
    );
    return isInvalid ? (
        <>
            <div className={fakeFormControlClasses} style={{ display: 'none' }} />
            <div className={classes}>
                <span>{error}</span>
            </div>
        </>
    ) : null;
};
