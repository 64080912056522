import React, {  } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Brand } from 'services/ApiService/Insight/InsightApiClient';
import { ValidatedField } from '../Validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';

export interface IAddBrandsProps {
    id: string;
    className?: string;
    inputClassName?: string;
    onChange: (products: Brand[]) => void;
    selected: Brand[];
    touched: boolean | undefined;
    errors: string | undefined;
    required?: boolean;
    label: string;
    placeholder: string;
}
export const AddBrands: React.FC<IAddBrandsProps> = ({ required = false, label, id, className, inputClassName, selected, errors, touched, placeholder, onChange }) => {

    const handleChange = (values: Option[]) => {
        onChange(values as Brand[]);
    };

    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={errors}
        required={required}
        className={className}
    >
        <Typeahead
            id="brands"
            clearButton
            caseSensitive={false}
            options={Object.values(Brand)}
            placeholder={placeholder}
            onChange={handleChange}
            selected={selected}
            selectHint={(shouldSelect, e) => {
                const key = e.key || e.keyCode;
                return key === 'Enter' || key === 13 || shouldSelect;
            }}
            inputProps={{
                id: 'brands',
                className: inputClassName,
                onInput: (e) => !e.currentTarget.value && (e.nativeEvent as InputEvent).inputType !== 'deleteContentBackward' && handleChange([]),
            }}
            multiple={true}
            flip={true}
            allowNew={false}
        />
    </ValidatedField>;
};