import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Language } from 'services/ApiService/Insight/InsightApiClient';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';
import { getAllLanguages } from 'store/Referential/ReferentialThunk';
import { useAppContext } from '../AppProvider';
import { Loading } from '../Loading';
import { ValidatedField } from '../Validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';

export interface IAddLanguageProps {
    id: string;
    className?: string;
    inputClassName?: string;
    onChange: (language: Language) => void;
    selected: string;
    touched: boolean | undefined;
    errors: string | undefined;
    required?: boolean;
    label: string;
    placeholder: string;
}
export const AddLanguage: React.FC<IAddLanguageProps> = ({ required = false, label, id, className, inputClassName, selected, errors, touched, placeholder, onChange }) => {

    const { dispatch, state: { entities, referential: { languages: { data } } } } = useAppContext();

    const languages = InsightSelectors.getLanguageArray(data, entities.insight);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        try {
            dispatch(getAllLanguages());
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    const handleChange = (values: Option[]) => {
        onChange(values?.pop() as Language);
    };

    if (isLoading || !languages || languages.length == 0) {
        return <div className="d-flex justify-content-center py-2">
            <Loading />
        </div>;
    }
    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={errors}
        required={required}
        className={className}
    >
        <Typeahead
            id="language"
            clearButton
            labelKey={language => (language as Language).name || ''}
            caseSensitive={false}
            options={sortBy(languages, ['name'])}
            placeholder={placeholder}
            onChange={handleChange}
            selected={languages.filter(item => item.isoCode === selected)}
            inputProps={{
                id: 'languages',
                className: inputClassName,
                onInput: (e) => !e.currentTarget.value && (e.nativeEvent as InputEvent).inputType !== 'deleteContentBackward' && handleChange([]),
            }}
            multiple={true}
        />
    </ValidatedField>;
};
