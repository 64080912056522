import classNames from 'classnames';
import * as React from 'react';
import { DatePicker } from '../Picker/DatePicker';
import { ValidatedField } from './ValidatedField';
import { IValidatedDatePickerProps } from './ValidationModels';

export const ValidatedDatePicker: React.FC<IValidatedDatePickerProps> = (props) => {
    const { id, label, touched, error, required, className, value, onChange, ...inputProps } = props;
    const isInvalid = touched && !!error;
    const isValid = touched && !isInvalid;
    const inputClasses = classNames(
        {
            'is-invalid': !!isInvalid,
            'is-valid': !!isValid,
        },
    );

    delete inputProps.children;

    return (
        <ValidatedField
            id={id}
            label={label}
            touched={touched}
            error={error}
            required={required}
            className={className}
        >
            <DatePicker
                id={id}
                value={value?.toString() || null}
                onChange={onChange}
                className={inputClasses}
            />
        </ValidatedField>
    );
};