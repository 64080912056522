import React, { useRef } from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { ValidatedField } from '../Validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export interface IAddPubHeadsProps {
    id: string;
    className?: string;
    inputClassName?: string;
    onChange: (pubHeadIds: number[]) => void;
    selected: number[];
    touched: boolean | undefined;
    errors: string | undefined;
    required?: boolean;
    label: string;
    placeholder: string;
}

type NumberOption = Option & { label: string };

export const AddPubHeads: React.FC<IAddPubHeadsProps> = ({ required = false, label, id, className, inputClassName, selected, errors, touched, placeholder, onChange }) => {
    const menuItemRef = useRef<HTMLDivElement>(null);
    const handleChange = (pubHeadIds: Option[]) => {
        onChange(pubHeadIds.map(p => parseInt((p as NumberOption).label)));
    };

    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={errors}
        required={required}
        className={className}
    >
        <Typeahead
            allowNew={(_, { text }) => !selected.includes(parseInt(text))}
            id="pubHeadIds"
            multiple
            onChange={handleChange}
            placeholder={placeholder}
            inputProps={{
                type: 'number',
                className: inputClassName,
            }}
            onKeyDown={(e) => {
                const key = e.key || e.keyCode;
                if (key === 'Enter' || key === 13) {
                    menuItemRef.current?.click();
                }
            }}
            selected={selected.map(p => ({ label: p.toString() } as NumberOption))}
            options={[]}
            renderMenu={(results, menuProps) => (
                <Menu id={menuProps.id}>
                    {results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                            <div ref={menuItemRef}>
                                Add Pub Head <b>{`${(result as NumberOption).label}`}</b>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        />
    </ValidatedField>;
};