import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Product } from 'services/ApiService/Insight/InsightApiClient';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';
import { getAllProducts } from 'store/Referential/ReferentialThunk';
import { useAppContext } from '../AppProvider';
import { Loading } from '../Loading';
import { ValidatedField } from '../Validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option } from 'react-bootstrap-typeahead/types/types';

export interface IAddProductsProps {
    id: string;
    className?: string;
    inputClassName?: string;
    onChange: (products: Product[]) => void;
    selected: number[];
    touched: boolean | undefined;
    errors: string | undefined;
    required?: boolean;
    label: string;
    placeholder: string;
}
export const AddProducts: React.FC<IAddProductsProps> = ({ required = false, label, id, className, inputClassName, selected, errors, touched, placeholder, onChange }) => {

    const { dispatch, state: { entities, referential: { products: { data } } } } = useAppContext();
    const products = InsightSelectors.getProductArray(data, entities.insight);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        try {
            dispatch(getAllProducts());
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    const handleChange = (values: Option[]) => {
        onChange(values as Product[]);
    };
    if (isLoading || products.length == 0) {
        return <div className="d-flex justify-content-center py-2">
            <Loading />
        </div>;
    }
    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={errors}
        required={required}
        className={className}
    >
        <Typeahead
            id="productIds"
            clearButton
            paginate={true}
            maxResults={25}
            labelKey={product => (product as Product).name ?? ''}
            caseSensitive={false}
            options={sortBy(products, ['name'])}
            placeholder={placeholder}
            onChange={handleChange}
            selected={products.filter(item => selected.find(id => id == item.id))}
            selectHint={(shouldSelect, e) => {
                const key = e.key || e.keyCode;
                return key === 'Enter' || key === 13 || shouldSelect;
            }}
            inputProps={{
                id: 'products',
                className: inputClassName,
                onInput: (e) => !e.currentTarget.value && (e.nativeEvent as InputEvent).inputType !== 'deleteContentBackward' && handleChange([]),
            }}
            multiple={true}
            flip={true}
            allowNew={false}
        />
    </ValidatedField>;
};