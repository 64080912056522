import * as React from 'react';
import { IValidationLabel } from './ValidationModels';

export const ValidationLabel: React.FC<IValidationLabel> = (props) => {
    const { children, required, ...labelProps } = props;

    return children ? (
        <label {...labelProps}>
            {children} {required && '*'}
        </label>
    ) : null;
};
