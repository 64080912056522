import { AppContextAction } from './AppContext/AppContextActions';
import { AppContextReducer } from './AppContext/AppContextReducer';
import { NormalizrAction } from './Normalizr/NormalizrAction';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { PagesAction } from './Pages/PagesActions';
import { PagesReducer } from './Pages/PagesReducer';
import { PersonsAction } from './Persons/PersonsActions';
import { PersonsReducer } from './Persons/PersonsReducer';
import { ReferentialAction } from './Referential/ReferentialActions';
import { ReferentialReducer } from './Referential/ReferentialReducer';

import { DynamicMenusAction } from './DynamicMenus/DynamicMenusActions';
import { DynamicMenusReducer } from './DynamicMenus/DynamicMenusReducer';

import { Reducer as BaseReducer, combineReducers } from './storeHelper';

export type Action =
    | { type: 'TEST' }
    | AppContextAction
    | NormalizrAction
    | PagesAction
    | PersonsAction
    | ReferentialAction
    | DynamicMenusAction;

const reducers = {
    appContext: AppContextReducer,
    entities: NormalizrReducer,
    pages: PagesReducer,
    persons: PersonsReducer,
    referential: ReferentialReducer,
    dynamicMenus: DynamicMenusReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export const reducer = combineReducers<State, Action>(reducers);

export const initialState = reducer({} as State, {} as any);

export type Reducer<S = any> = BaseReducer<S, Action>;
