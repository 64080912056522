import { schema } from 'normalizr';
import { IInsightEntities } from 'services/ApiService';

const createNormalizrPayload = (entitySchema: schema.Entity<any>, entityIds: (string | number)[]) => {
    return {
        [entitySchema.key]: entityIds ? entityIds.reduce((prev, id) => {
            prev[id] = null;
            return prev;
        }, {} as { [id: string]: null; }) : null,
    };
};

const mergeEntities = <T, TU extends string>(type: TU) => (payload: T) => {
    return {
        type,
        payload,
    } as const;
};

const removeEntities = <TU extends string>(type: TU) => (entitySchema: schema.Entity, entityIds: Array<string | number>) => {
    const payload = createNormalizrPayload(entitySchema, entityIds);
    return {
        type,
        payload,
    } as const;
};

export const mergeInsightEntities = mergeEntities<IInsightEntities, '@NORMALIZR/MERGE_INSIGHT_ENTITIES'>('@NORMALIZR/MERGE_INSIGHT_ENTITIES');
export const removeInsightEntities = removeEntities<'@NORMALIZR/REMOVE_INSIGHT_ENTITIES'>('@NORMALIZR/REMOVE_INSIGHT_ENTITIES');

export type NormalizrAction =
    | ReturnType<typeof mergeInsightEntities>
    | ReturnType<typeof removeInsightEntities>;
