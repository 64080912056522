export interface IStandardEnum {
    [nu: number]: string;
}

export interface IEnumValue<T> {
    value: T;
    name: string;
}

export const getEnumValues = <T>(enumType: IStandardEnum) => {
    return Object.keys(enumType)
        .map((k) => (enumType as Record<string, string>)[k])
        .map((v) => v as unknown as T)
        .sort();
};
