export const createFetchUniversesAction = () => {
    return {
        type: '@UNIVERSES/FETCH_UNIVERSES',
    } as const;
};

export const createFetchUniversesSuccessAction = (universeIds: number[]) => {
    return {
        type: '@UNIVERSES/FETCH_UNIVERSES_SUCCESS',
        payload: {
            universeIds,
        },
    } as const;
};

export const createFetchUniversesFailureAction = () => {
    return {
        type: '@UNIVERSES/FETCH_UNIVERSES_FAILURE',
    } as const;
};

export const createFetchProductsAction = () => {
    return {
        type: '@PRODUCTS/FETCH_PRODUCTS',
    } as const;
};

export const createFetchProductsSuccessAction = (productIds: number[]) => {
    return {
        type: '@PRODUCTS/FETCH_PRODUCTS_SUCCESS',
        payload: {
            productIds,
        },
    } as const;
};

export const createFetchProductsFailureAction = () => {
    return {
        type: '@PRODUCTS/FETCH_PRODUCTS_FAILURE',
    } as const;
};

export const createFetchCategoriesAction = () => {
    return {
        type: '@CATEGORIES/FETCH_CATEGORIES',
    } as const;
};

export const createFetchCategoriesSuccessAction = (categoriesId: number[]) => {
    return {
        type: '@CATEGORIES/FETCH_CATEGORIES_SUCCESS',
        payload: {
            categoriesId,
        },
    } as const;
};

export const createFetchCategoriesFailureAction = () => {
    return {
        type: '@CATEGORIES/FETCH_CATEGORIES_FAILURE',
    } as const;
};

export const createFetchCategoryAssetClassesAction = () => {
    return {
        type: '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES',
    } as const;
};

export const createFetchCategoryAssetClassesSuccessAction = (categoryAssetClassIds: number[]) => {
    return {
        type: '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES_SUCCESS',
        payload: {
            categoryAssetClassIds,
        },
    } as const;
};

export const createFetchCategoryAssetClassesFailureAction = () => {
    return {
        type: '@CATEGORY_ASSET_CLASSES/FETCH_CATEGORY_ASSET_CLASSES_FAILURE',
    } as const;
};

export const createFetchKeywordsAction = () => {
    return {
        type: '@KEYWORDS/FETCH_KEYWORDS',
    } as const;
};

export const createFetchKeywordsSuccessAction = (keywordIds: number[]) => {
    return {
        type: '@KEYWORDS/FETCH_KEYWORDS_SUCCESS',
        payload: {
            keywordIds,
        },
    } as const;
};

export const createFetchKeywordsFailureAction = () => {
    return {
        type: '@KEYWORDS/FETCH_KEYWORDS_FAILURE',
    } as const;
};

export const createFetchSectorsAction = () => {
    return {
        type: '@SECTORS/FETCH_SECTORS',
    } as const;
};

export const createFetchSectorsSuccessAction = (sectorIds: number[]) => {
    return {
        type: '@SECTORS/FETCH_SECTORS_SUCCESS',
        payload: {
            sectorIds,
        },
    } as const;
};

export const createFetchSectorsFailureAction = () => {
    return {
        type: '@SECTORS/FETCH_SECTORS_FAILURE',
    } as const;
};

export const createFetchLanguagesAction = () => {
    return {
        type: '@LANGUAGES/FETCH_LANGUAGES',
    } as const;
};

export const createFetchLanguagesSuccessAction = (languageIsoCodes: string[]) => {
    return {
        type: '@LANGUAGES/FETCH_LANGUAGES_SUCCESS',
        payload: {
            languageIsoCodes,
        },
    } as const;
};

export const createFetchLanguagesFailureAction = () => {
    return {
        type: '@LANGUAGES/FETCH_LANGUAGES_FAILURE',
    } as const;
};

export type ReferentialAction =
    | ReturnType<typeof createFetchUniversesAction>
    | ReturnType<typeof createFetchUniversesSuccessAction>
    | ReturnType<typeof createFetchUniversesFailureAction>
    | ReturnType<typeof createFetchProductsAction>
    | ReturnType<typeof createFetchProductsSuccessAction>
    | ReturnType<typeof createFetchProductsFailureAction>
    | ReturnType<typeof createFetchCategoriesAction>
    | ReturnType<typeof createFetchCategoriesSuccessAction>
    | ReturnType<typeof createFetchCategoriesFailureAction>
    | ReturnType<typeof createFetchCategoryAssetClassesAction>
    | ReturnType<typeof createFetchCategoryAssetClassesSuccessAction>
    | ReturnType<typeof createFetchCategoryAssetClassesFailureAction>
    | ReturnType<typeof createFetchKeywordsAction>
    | ReturnType<typeof createFetchKeywordsSuccessAction>
    | ReturnType<typeof createFetchKeywordsFailureAction>
    | ReturnType<typeof createFetchSectorsAction>
    | ReturnType<typeof createFetchSectorsSuccessAction>
    | ReturnType<typeof createFetchSectorsFailureAction>
    | ReturnType<typeof createFetchLanguagesAction>
    | ReturnType<typeof createFetchLanguagesSuccessAction>
    | ReturnType<typeof createFetchLanguagesFailureAction>
