import { normalize } from 'normalizr';
import { ThunkAction } from 'components/common/AppProvider';
import { PersonsApi, InsightApiSchema } from 'services/ApiService';
import { Person } from 'services/ApiService/Insight/InsightApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergeInsightEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchNavigateAsPersonsAction,
    createFetchNavigateAsPersonsFailureAction,
    createFetchNavigateAsPersonsSuccessAction,
    createFetchPersonsAction,
    createFetchPersonsFailureAction,
    createFetchPersonsSuccessAction,
} from './PersonsActions';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';

export const getNavigateAsPersons = (): ThunkAction<Promise<Person[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.persons.navigateAs.isFetching || state.persons.navigateAs.data) {
        const promise = PromiseStore.get<Person[]>('getNavigateAsPersons');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await PersonsApi.getNavigateAsPersons();
            const normalizedData = normalize(data.persons, InsightApiSchema.PersonSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchNavigateAsPersonsSuccessAction(normalizedData.result));
            return data.persons || [];
        })();

        PromiseStore.set(fetchTask, 'getNavigateAsPersons');

        dispatch(createFetchNavigateAsPersonsAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchNavigateAsPersonsFailureAction());
        logError(error);
        throw error;
    }
};

export const getPersons = (icIds: string[]): ThunkAction<Promise<Person[]>> => async (dispatch, getState) => {
    const state = getState();

    const distinctIcIds = [...new Set(icIds.filter(id => !!id))];
    const missingIcIds = distinctIcIds
        .filter(icId => {
            const personRequest = state.persons.persons[icId];
            return !personRequest || !personRequest.isFetching && personRequest.didInvalidate;
        });

    const fetchingPersonTasks = distinctIcIds
        .filter(icId => !missingIcIds.includes(icId))
        .map(icId => PromiseStore.get<Person[]>('getPerson', icId)
            || Promise.resolve([]));

    if (!missingIcIds.length) {
        await Promise.all(fetchingPersonTasks);
        const state = getState();
        return InsightSelectors.getPersonArray(distinctIcIds, state.entities.insight);
    }

    try {
        const fetchTask = (async () => {
            const data = await PersonsApi
                .getPersons({
                    icIds: missingIcIds,
                });
            const normalizedData = normalize(data.persons, InsightApiSchema.PersonSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchPersonsSuccessAction(normalizedData.result));
            const state = getState();
            return InsightSelectors.getPersonArray(distinctIcIds, state.entities.insight);
        })();

        for (const icId of missingIcIds) {
            PromiseStore.set(fetchTask, 'getPerson', icId);
        }

        dispatch(createFetchPersonsAction(missingIcIds));

        await Promise.all([fetchTask, ...fetchingPersonTasks]);

        const state = getState();
        return InsightSelectors.getPersonArray(distinctIcIds, state.entities.insight);
    }
    catch (error) {
        dispatch(createFetchPersonsFailureAction(missingIcIds));
        logError(error);
        throw error;
    }
};
