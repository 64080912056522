import * as React from 'react';
import image500 from 'images/500.svg';
import { Error } from './Error';

interface NotAuthorizedProps {
  isDisplayTryAgain?: boolean;
  message?: string;
  messageClassName?: string;
  className?: string;
}

export default (({
  isDisplayTryAgain,
  message = 'Sorry, you are not Authorized to access/modify the requested ressource.',
  messageClassName,
  className,
}) => (
  <Error
    code={403}
    title="Not Authorized"
    message={message}
    imageUrl={image500}
    isDisplayTryAgain={isDisplayTryAgain}
    messageClassName={messageClassName}
    className={className}
  />
)) as React.FC<NotAuthorizedProps>;
