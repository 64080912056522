import classNames from 'classnames';
import * as React from 'react';
import { ValidatedField } from './ValidatedField';
import { IValidatedTextareaProps } from './ValidationModels';

const ValidatedTextareaComponent: React.FC<IValidatedTextareaProps> = (props) => {
    const { id, label, touched, error, required, className, textareaRef, ...inputProps } = props;
    const isInvalid = touched && !!error;
    const isValid = touched && !isInvalid;
    const inputClasses = classNames(
        'form-control',
        {
            'is-invalid': !!isInvalid,
            'is-valid': !!isValid,
        },
    );

    delete inputProps.children;

    return (
        <ValidatedField
            id={id}
            label={label}
            touched={touched}
            error={error}
            required={required}
            className={className}
        >
            <textarea
                id={id}
                {...inputProps}
                ref={textareaRef}
                className={inputClasses}
            />
        </ValidatedField>
    );
};

export const ValidatedTextarea = React.forwardRef<HTMLTextAreaElement, IValidatedTextareaProps>((props, ref) => <ValidatedTextareaComponent
    textareaRef={ref} {...props}
/>);
