import { ApiRequest, Reducer } from 'store';

export interface IPageRequestMap {
    readonly [shortName: string]: ApiRequest;
}

export interface IPageUpdateRequestMap {
    readonly [shortName: string]: boolean;
}

export interface IWidgetUpdateRequestMap {
    readonly [widgetId: number]: boolean;
}

export type SearchPageRequest = ApiRequest<string[]> & {
    searchTerms?: string;
    skip: number;
    take: number;
    totalResults: number;
};

export const initialState = {
    pages: {} as IPageRequestMap,
    update: {} as IPageUpdateRequestMap,
    updateWidget: {} as IWidgetUpdateRequestMap,
    search: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
        searchTerms: undefined,
        skip: 0,
        take: 20,
        totalResults: 0,
    } as SearchPageRequest,
    all: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
        totalResults: 0,
    } as SearchPageRequest,
} as const;

export type PagesState = typeof initialState;

export const PagesReducer: Reducer<PagesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PAGES/SET_SEARCH_TERMS':
            return {
                ...state,
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                        searchTerms: action.payload.searchTerms,
                    }),
            };

        case '@PAGES/SET_PAGINATION':
            return {
                ...state,
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    }),
            };

        case '@PAGES/SEARCH_PAGES':
            return {
                ...state,
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/SEARCH_PAGES_SUCCESS':
            return {
                ...state,
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.pageShortNames,
                        totalResults: action.payload.totalResults,
                    }),
            };

        case '@PAGES/SEARCH_PAGES_FAILURE':
            return {
                ...state,
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/GET_ALL_PAGES':
            return {
                ...state,
                all: createGetAllPagesState(
                    state,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/GET_ALL_PAGES_SUCCESS':
            return {
                ...state,
                all: createGetAllPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.pageShortNames,
                        totalResults: action.payload.totalResults,
                    }),
            };

        case '@PAGES/GET_ALL_PAGES_FAILURE':
            return {
                ...state,
                all: createGetAllPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/FETCH_PAGE':
            return {
                ...state,
                pages: createFetchPageState(
                    state,
                    action.payload.shortName,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/FETCH_PAGE_SUCCESS':
            return {
                ...state,
                pages: createFetchPageState(
                    state,
                    action.payload.shortName,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PAGES/FETCH_PAGE_FAILURE':
            return {
                ...state,
                pages: createFetchPageState(
                    state,
                    action.payload.shortName,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/UPDATE_PAGE':
            return {
                ...state,
                update: createUpdatePageState(
                    state,
                    action.payload.shortName,
                    true),
            };

        case '@PAGES/UPDATE_PAGE_SUCCESS':
            return {
                ...state,
                update: createUpdatePageState(
                    state,
                    action.payload.shortName,
                    false),
                pages: createFetchPageState(
                    state,
                    action.payload.shortName,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: createSearchPagesState(
                    state,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/UPDATE_PAGE_FAILURE':
            return {
                ...state,
                update: createUpdatePageState(
                    state,
                    action.payload.shortName,
                    false),
            };

        case '@PAGES/UPDATE_WIDGET':
            return {
                ...state,
                updateWidget: createUpdateWidgetState(
                    state,
                    action.payload.widgetId,
                    true),
            };

        case '@PAGES/UPDATE_WIDGET_SUCCESS':
            return {
                ...state,
                updateWidget: createUpdateWidgetState(
                    state,
                    action.payload.widgetId,
                    false),
                pages: createFetchPageState(
                    state,
                    action.payload.shortName,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PAGES/UPDATE_WIDGET_FAILURE':
            return {
                ...state,
                updateWidget: createUpdateWidgetState(
                    state,
                    action.payload.widgetId,
                    false),
            };

        default:
            return state;
    }
};

const createSearchPagesState = (state: PagesState, apiRequest: Partial<SearchPageRequest>) => {
    return {
        ...state.search,
        ...apiRequest,
    };
};

const createGetAllPagesState = (state: PagesState, apiRequest: Partial<SearchPageRequest>) => {
    return {
        ...state.all,
        ...apiRequest,
    };
};

const createFetchPageState = (state: PagesState, shortName: string, apiRequest: ApiRequest) => {
    const normalizedShortName = shortName.toLowerCase();
    return {
        ...state.pages,
        [normalizedShortName]: {
            ...state.pages[normalizedShortName],
            ...apiRequest,
        },
    };
};

const createUpdatePageState = (state: PagesState, shortName: string, isFetching: boolean) => {
    const normalizedShortName = shortName.toLowerCase();
    return {
        ...state.update,
        [normalizedShortName]: isFetching,
    };
};

const createUpdateWidgetState = (state: PagesState, widgetId: number, isFetching: boolean) => {
    return {
        ...state.update,
        [widgetId]: isFetching,
    };
};
