import { ThunkAction } from 'components/common/AppProvider';
import { normalize } from 'normalizr';
import { InsightApiSchema, ReferentialsApi } from 'services/ApiService';
import {
    Category,
    CategoryAssetClass,
    Keyword,
    Language,
    Product,
    Sector,
    Universe,
} from 'services/ApiService/Insight/InsightApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergeInsightEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchProductsAction,
    createFetchProductsFailureAction,
    createFetchProductsSuccessAction,
    createFetchUniversesAction,
    createFetchUniversesFailureAction,
    createFetchUniversesSuccessAction,
    createFetchCategoriesAction,
    createFetchCategoriesFailureAction,
    createFetchCategoriesSuccessAction,
    createFetchCategoryAssetClassesAction,
    createFetchCategoryAssetClassesFailureAction,
    createFetchCategoryAssetClassesSuccessAction,
    createFetchKeywordsAction,
    createFetchKeywordsFailureAction,
    createFetchKeywordsSuccessAction,
    createFetchSectorsAction,
    createFetchSectorsFailureAction,
    createFetchSectorsSuccessAction,
    createFetchLanguagesSuccessAction,
    createFetchLanguagesAction,
    createFetchLanguagesFailureAction,
} from './ReferentialActions';


export const getAllUniverses = (): ThunkAction<Promise<Universe[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.universes.isFetching || !state.referential.universes.didInvalidate) {
        const promise = PromiseStore.get<Universe[]>('getAllUniverses');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getUniverses();
            const normalizedData = normalize(data.universes, InsightApiSchema.UniverseSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchUniversesSuccessAction(normalizedData.result));
            return data.universes || [];
        })();
        PromiseStore.set(fetchTask, 'getAllUniverses');
        dispatch(createFetchUniversesAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchUniversesFailureAction());
        logError(error);
        throw error;
    }
};

export const getAllProducts = (): ThunkAction<Promise<Product[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.products.isFetching || !state.referential.products.didInvalidate) {
        const promise = PromiseStore.get<Product[]>('getAllProducts');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getProducts();
            const normalizedData = normalize(data.products, InsightApiSchema.ProductSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchProductsSuccessAction(normalizedData.result));
            return data.products || [];
        })();
        PromiseStore.set(fetchTask, 'getAllProducts');
        dispatch(createFetchProductsAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchProductsFailureAction());
        logError(error);
        throw error;
    }



};

export const getAllCategories = (): ThunkAction<Promise<Category[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.categories.isFetching || !state.referential.categories.didInvalidate) {
        const promise = PromiseStore.get<Category[]>('getAllCategories');

        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getCategories();
            const normalizedData = normalize(data.categories, InsightApiSchema.CategorySchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchCategoriesSuccessAction(normalizedData.result));
            return data.categories || [];
        })();
        PromiseStore.set(fetchTask, 'getAllCategories');
        dispatch(createFetchCategoriesAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchCategoriesFailureAction());
        logError(error);
        throw error;
    }
};

export const getAllCategoryAssetClasses = (): ThunkAction<Promise<CategoryAssetClass[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.categoryAssetClasses.isFetching || !state.referential.categoryAssetClasses.didInvalidate) {
        const promise = PromiseStore.get<CategoryAssetClass[]>('getAllCategoryAssetClasses');

        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getCategoryAssetClasses();
            const normalizedData = normalize(data.categoryAssetClasses, InsightApiSchema.CategoryAssetClassSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchCategoryAssetClassesSuccessAction(normalizedData.result));
            return data.categoryAssetClasses || [];
        })();
        PromiseStore.set(fetchTask, 'getAllCategoryAssetClasses');
        dispatch(createFetchCategoryAssetClassesAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchCategoryAssetClassesFailureAction());
        logError(error);
        throw error;
    }
};

export const getAllKeywords = (): ThunkAction<Promise<Keyword[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.keywords.isFetching || !state.referential.keywords.didInvalidate) {
        const promise = PromiseStore.get<Keyword[]>('getAllKeywords');

        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getAllKeywords();
            const normalizedData = normalize(data.keywords, InsightApiSchema.KeywordSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchKeywordsSuccessAction(normalizedData.result));
            return data.keywords || [];
        })();
        PromiseStore.set(fetchTask, 'getAllKeywords');
        dispatch(createFetchKeywordsAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchKeywordsFailureAction());
        logError(error);
        throw error;
    }
};

export const getAllSectors = (): ThunkAction<Promise<Sector[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.sectors.isFetching || !state.referential.sectors.didInvalidate) {
        const promise = PromiseStore.get<Sector[]>('getAllSectors');

        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getSectors(true);
            const normalizedData = normalize(data.sectors, InsightApiSchema.SectorSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchSectorsSuccessAction(normalizedData.result));
            return data.sectors || [];
        })();
        PromiseStore.set(fetchTask, 'getAllSectors');
        dispatch(createFetchSectorsAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchSectorsFailureAction());
        logError(error);
        throw error;
    }
};

export const getAllLanguages = (): ThunkAction<Promise<Language[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.referential.languages.isFetching || !state.referential.languages.didInvalidate) {
        const promise = PromiseStore.get<Language[]>('getAllLanguages');

        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getLanguages();
            const normalizedData = normalize(data.languages, InsightApiSchema.LanguageSchemaArray);
            dispatch(mergeInsightEntities(normalizedData.entities));
            dispatch(createFetchLanguagesSuccessAction(normalizedData.result));
            return data.languages || [];
        })();
        PromiseStore.set(fetchTask, 'getAllLanguages');
        dispatch(createFetchLanguagesAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchLanguagesFailureAction());
        logError(error);
        throw error;
    }
};
