import classNames from 'classnames';
import * as React from 'react';
import { Checkbox } from '../Checkbox';
import { ValidatedField } from './ValidatedField';
import { IValidatedInputProps } from './ValidationModels';
import { SwitchBox } from '../SwicthBox';

export const ValidatedInput: React.FC<IValidatedInputProps> = (props) => {
    const { id, label, type, touched, error, children, required, className, ...inputProps } = props;
    const isInvalid = touched && error;
    const isValid = touched && !isInvalid;
    const inputClasses = classNames(
        {
            'form-control': type !== 'checkbox' && type!== 'switchbox',
            'is-invalid': !!isInvalid,
            'is-valid': !!isValid,
        },
    );

    switch (type) {
        case 'checkbox':
            return (
                <ValidatedField
                    id={id}
                    label={label}
                    touched={touched}
                    error={error}
                    required={required}
                    className={className}
                >
                    <Checkbox
                        id={id}
                        {...inputProps}
                        className={inputClasses}
                    >
                        {children}
                    </Checkbox>
                </ValidatedField>
            );
        case 'switchbox':
            return (
                <ValidatedField
                    id={id}
                    label={label}
                    touched={touched}
                    error={error}
                    required={required}
                    className={className}
                >
                    <SwitchBox 
                        id={id}
                        {...inputProps}
                        className={inputClasses}
                    >
                        {children}
                    </SwitchBox>
                </ValidatedField>
            );
        default:
            return (
                <ValidatedField
                    id={id}
                    label={label}
                    touched={touched}
                    error={error}
                    required={required}
                    className={className}
                >
                    <input
                        id={id}
                        type={type}
                        {...inputProps}
                        className={inputClasses}
                    >
                        {children}
                    </input>
                </ValidatedField>
            );
    }
};
