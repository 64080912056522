import classNames from 'classnames';
import * as React from 'react';

export class Checkbox extends React.PureComponent<React.InputHTMLAttributes<HTMLInputElement>> {
    public render() {
        const { id, name, value, className, children } = this.props;

        const inputProps = { ... this.props };
        delete inputProps.children;

        const inputId = id || `checkbox-${name}-${value}`;
        const classes = classNames('custom-control custom-checkbox', className);
        return (
            <div className={classes}>
                <input
                    {...inputProps}
                    id={inputId}
                    className="custom-control-input"
                    type="checkbox"
                />
                <label
                    className="custom-control-label"
                    htmlFor={inputId}
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    {children}
                </label>
            </div>
        );
    }
}
