import {
    LoggedUser,
    Permission,
} from './ApiService/Insight/InsightApiClient';

export enum Permissions {
    Administrator = 'Administrator',
    Banner = 'Banner',
    Contacts = 'Contacts',
    Highlights = 'Highlights',
    HtmlContent = 'HtmlContent',
    Interactive = 'Interactive',
    KeyReports = 'KeyReports',
    LatestMovements = 'LatestMovements',
    LatestReports = 'LatestReports',
    MacroBlog = 'MacroBlog',
    MostViewed = 'MostViewed',
    RecommendedPublications = 'RecommendedPublications',
    Toolbox = 'Toolbox',
}

const convertUserPermissions = (user?: LoggedUser | null): Permission[] => {
    if (!user) {
        return [];
    }

    if (user.permissions && user.permissions.length) {
        return user.permissions.map(up => ({
            name: up.name as Permissions,
            constraints: up.constraints,
        }));
    }
    return [];
};

export const hasRight = (user?: LoggedUser | null, permission?: Permissions, pageShortName?: string): boolean => {
    const userPermissions = convertUserPermissions(user);

    if (userPermissions && userPermissions.length) {
        return !!userPermissions.find(userPermission => userPermission.name === permission
            && (
                !userPermission.constraints
                || !userPermission.constraints.length
                || pageShortName && userPermission.constraints?.find(item => item?.toLowerCase() === pageShortName.toLowerCase())));
    }
    return false;
};

export const hasAnyRight = (user: LoggedUser | null | undefined, permissions: Permissions[], pageShortName?: string): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return permissions.some(permission => hasRight(user, permission, pageShortName));
    }
    return false;
};
