import React, { FC } from 'react';
import { enUS } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#6e777a',
        },
    },
    transitions: { create: () => 'none' },
});

interface IMaterialUiProviderProps {
    children?: React.ReactNode;
}


export const MaterialUiProvider: FC<IMaterialUiProviderProps> = ({ children }) => (
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enUS}>
            {children}
        </LocalizationProvider>
    </ThemeProvider>
);
