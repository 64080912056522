export const createSetSearchTermsAction = (searchTerms?: string) => {
    return {
        type: '@DYNAMIC_MENUS/SET_SEARCH_TERMS',
        payload: {
            searchTerms,
        },
    } as const;
};

export const createSetPaginationAction = (skip: number, take: number) => {
    return {
        type: '@DYNAMIC_MENUS/SET_PAGINATION',
        payload: {
            skip,
            take,
        },
    } as const;
};

export const createSearchDynamicMenusAction = () => {
    return {
        type: '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS',
    } as const;
};

export const createSearchDynamicMenusSuccessAction = (dynamicMenuIds: number[], totalResults: number) => {
    return {
        type: '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS_SUCCESS',
        payload: {
            dynamicMenuIds,
            totalResults,
        },
    } as const;
};

export const createSearchDynamicMenusFailureAction = () => {
    return {
        type: '@DYNAMIC_MENUS/SEARCH_DYNAMIC_MENUS_FAILURE',
    } as const;
};


export const createCreateDynamicMenuAction = (name: string) => {
    return {
        type: '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU',
        payload: {
            name,
        },
    } as const;
};

export const createCreateDynamicMenuSuccessAction = (name: string, id: number) => {
    return {
        type: '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU_SUCCESS',
        payload: {
            name,
            id,
        },
    } as const;
};

export const createCreateDynamicMenuFailureAction = (name: string) => {
    return {
        type: '@DYNAMIC_MENUS/CREATE_DYNAMIC_MENU_FAILURE',
        payload: {
            name,
        },
    } as const;
};

export const createUpdateDynamicMenuAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuSuccessAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU_SUCCESS',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuFailureAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/UPDATE_DYNAMIC_MENU_FAILURE',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuSuccessAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU_SUCCESS',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuFailureAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENUS/DELETE_DYNAMIC_MENU_FAILURE',
        payload: {
            id,
        },
    } as const;
};

export const createFetchDynamicMenusAction = () => {
    return {
        type: '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS',
    } as const;
};

export const createFetchDynamicMenusSuccessAction = (dynamicMenus: number[]) => {
    return {
        type: '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS_SUCCESS',
        payload: {
            dynamicMenus,
        },
    } as const;
};

export const createFetchDynamicMenusFailureAction = () => {
    return {
        type: '@DYNAMIC_MENUS/FETCH_DYNAMIC_MENUS_FAILURE',
    } as const;
};

export const createCreateDynamicMenuItemsAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM',
        payload: {
            id,
        },
    } as const;
};

export const createCreateDynamicMenuItemsSuccessAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM_SUCCESS',
        payload: {
            id,
        },
    } as const;
};

export const createCreateDynamicMenuItemsFailureAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/CREATE_DYNAMIC_MENU_ITEM_FAILURE',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuItemsAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuItemsSuccessAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_SUCCESS',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuItemsFailureAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_FAILURE',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuItemsAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuItemsSuccessAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM_SUCCESS',
        payload: {
            id,
        },
    } as const;
};

export const createDeleteDynamicMenuItemsFailureAction = (id: number) => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/DELETE_DYNAMIC_MENU_ITEM_FAILURE',
        payload: {
            id,
        },
    } as const;
};

export const createUpdateDynamicMenuItemRanksAction = () => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS',
    } as const;
};

export const createUpdateDynamicMenuItemRanksSuccessAction = () => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS_SUCCESS',
    } as const;
};

export const createUpdateDynamicMenuItemRanksFailureAction = () => {
    return {
        type: '@DYNAMIC_MENU_ITEMS/UPDATE_DYNAMIC_MENU_ITEM_RANKS_FAILURE',
    } as const;
};

export type DynamicMenusAction =
    | ReturnType<typeof createSetSearchTermsAction>
    | ReturnType<typeof createSetPaginationAction>
    | ReturnType<typeof createSearchDynamicMenusAction>
    | ReturnType<typeof createSearchDynamicMenusSuccessAction>
    | ReturnType<typeof createSearchDynamicMenusFailureAction>
    | ReturnType<typeof createCreateDynamicMenuAction>
    | ReturnType<typeof createCreateDynamicMenuSuccessAction>
    | ReturnType<typeof createCreateDynamicMenuFailureAction>
    | ReturnType<typeof createUpdateDynamicMenuAction>
    | ReturnType<typeof createUpdateDynamicMenuSuccessAction>
    | ReturnType<typeof createUpdateDynamicMenuFailureAction>
    | ReturnType<typeof createDeleteDynamicMenuAction>
    | ReturnType<typeof createDeleteDynamicMenuSuccessAction>
    | ReturnType<typeof createDeleteDynamicMenuFailureAction>
    | ReturnType<typeof createFetchDynamicMenusAction>
    | ReturnType<typeof createFetchDynamicMenusSuccessAction>
    | ReturnType<typeof createFetchDynamicMenusFailureAction>
    | ReturnType<typeof createCreateDynamicMenuItemsAction>
    | ReturnType<typeof createCreateDynamicMenuItemsSuccessAction>
    | ReturnType<typeof createCreateDynamicMenuItemsFailureAction>
    | ReturnType<typeof createUpdateDynamicMenuItemsAction>
    | ReturnType<typeof createUpdateDynamicMenuItemsSuccessAction>
    | ReturnType<typeof createUpdateDynamicMenuItemsFailureAction>
    | ReturnType<typeof createDeleteDynamicMenuItemsAction>
    | ReturnType<typeof createDeleteDynamicMenuItemsSuccessAction>
    | ReturnType<typeof createDeleteDynamicMenuItemsFailureAction>
    | ReturnType<typeof createUpdateDynamicMenuItemRanksAction>
    | ReturnType<typeof createUpdateDynamicMenuItemRanksSuccessAction>
    | ReturnType<typeof createUpdateDynamicMenuItemRanksFailureAction>;