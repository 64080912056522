export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export enum SgConnectEnvironment {
    Homologation = 'homologation',
    Production = 'production',
}

export const getEnvironment = () => {
    let environment = Environment.Production;

    if (typeof window === 'undefined') {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'insight-admin-dev.sgmarkets.com'
        || window.location.hostname === 'insight-admin-dev.azureedge.net') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'insight-admin-uat.sgmarkets.com'
        || window.location.hostname === 'insight-admin-uat.azureedge.net') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'insight-admin.sgmarkets.com'
        || window.location.hostname === 'insight-admin-prd.azureedge.net'
        || window.location.hostname === 'insight-admin-preview.sgmarkets.com'
        || window.location.hostname === 'insight-admin-preview.azureedge.net') {
        environment = Environment.Production;
    }

    return environment;
};

interface IConfiguration {
    baseApiUrls: {
        insight: string;
    };
    requestAccessUrl: string;
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    iamResourceName: string;
    widgetCdnBaseUrl: string;
    sharedSgMarketsBaseUrl: string;
    externalLinks: {
        insight: string;
    };
}

const defaultConfiguration = {
    baseApiUrls: {
        insight: 'https://pretrade-api-insight-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://local.fr.world.socgen:5252',
    clientId: '55199b0a-f5b2-4c26-ad8c-d4b81a9283f7',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.pretrade-insight.v1',
        'api.pretrade-insight.admin.v1',
        'api.manage-contacts.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    iamResourceName: 'api.pretrade-insight',
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
    externalLinks: {
        insight: 'https://insight-uat.sgmarkets.com',
    },
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insight: 'https://pretrade-api-insight-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-admin-dev.sgmarkets.com',
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insight: 'https://pretrade-api-insight-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-admin-uat.sgmarkets.com',
        externalLinks: {
            ...defaultConfiguration.externalLinks,
            insight: 'https://insight-uat.sgmarkets.com',
        },
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insight: 'https://pretrade-api-insight-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://insight-admin.sgmarkets.com',
        clientId: '9ab75e0b-0911-44e5-903c-3c6aabc2301f',
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
        sgConnectEnvironment: SgConnectEnvironment.Production,
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com/',
        externalLinks: {
            ...defaultConfiguration.externalLinks,
            insight: 'https://insight.sgmarkets.com',
        },
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
