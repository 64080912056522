import { ToastMessage } from 'components/common/ToastMessage';
import React from 'react';
import { toast } from 'react-toastify';
import { LogsApi } from 'services/ApiService';
import { ApiException } from 'services/ApiService/Http';
import { ClientLogError, ClientLogFunctional } from 'services/ApiService/Insight/InsightApiClient';
import { initSGConnect } from 'services/SgConnect';

export interface IError {
    name?: string;
    message: string;
    stack?: string;
}

export let lastError: IError | undefined = undefined;

export const logError = (error: Error | unknown): Promise<void> => {
    if (error instanceof ApiException) {
        switch (error.status) {
            case 401:
                initSGConnect();
                return Promise.resolve();
        }
        return Promise.resolve();
    }

    if (error instanceof Error && error.name !== 'AbortError' && error.message !== 'Failed to fetch') {
        toast.error(
            <ToastMessage message={error.message} />,
            { toastId: error.message },
        );

        if (lastError?.message !== error.message) {
            lastError = {
                message: error.message,
                name: error.name,
                stack: error.stack,
            };
            const isDocumentDefined = typeof document !== 'undefined';
            const log: ClientLogError = {
                message: error.message,
                name: error.name,
                stackTrace: error.stack,
                pageUrl: isDocumentDefined ? document.location?.pathname : undefined,
            };

            LogsApi.error(log)
                .catch((err) => {
                    throw err;
                });
        }
    }

    return Promise.resolve();
};

export const doNotTrackEnabled = (): boolean => {
    if (typeof window === 'undefined') {
        return false;
    }

    const { navigator } = window;
    const dnt: any = (navigator.doNotTrack);

    if (!dnt) {
        return false;
    }

    return dnt === 'true' || dnt === 'yes' || (typeof dnt === 'string' && dnt.charAt(0) === '1');
};

export enum TrackedPageType {
    Home = 'Home',
    EditPage = 'EditPage',
    DynamicMenu = 'DynamicMenu'
}

export const track = (feature: string, message: string, parameters?: { [key: string]: number | string | boolean | undefined }): Promise<void> => {
    if (!doNotTrackEnabled()) {
        parameters = parameters ? Object.assign(parameters, { previousUrl: window.location.href }) : { previousUrl: window.location.href };
        const ClientLogFunctional: ClientLogFunctional = {
            message: message,
            customParameters: parameters,
            feature: feature,
        };
        LogsApi.functional(ClientLogFunctional).catch((err) => {
            throw err;
        });
    }

    return Promise.resolve();
};
